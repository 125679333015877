import { USER_AUTH } from "@/localStorage/storageKeys";
import { Storage } from "@/localStorage";
import { Open } from "@/request";
import store from "@/store";
import Socket from "@/plugins/socket";

const WAIT_TO_INIT_INTERCOM = 1000;

export const ACTIONS = {
  MODULE_PAYMENT: "modulePayment",
  DISTRIBUTOR_CRM: "distributorCRM",
};

const state = {
  loadingTheme: false,
  newBuildDetected: false,
  accessKey: null,
  token: null,
  blocked: null,
  company: null,
  distribuitor: null,
  email: null,
  id: null,
  integratorRole: null,
  name: null,
  role: null,
  surname: null,
  type: null,
  actions: {},
  isPiedSupport: false,
};

const mutations = {
  USER_DATA(state, payload) {
    if (payload?.id) {
      let accessKey = state.accessKey;
      for (const [k, v] of Object.entries(payload)) {
        if (k === "accessKey" && !accessKey) {
          accessKey = v;
          continue;
        }
        state[k] = v;
      }
      state.accessKey = accessKey;
      state.token = payload.token;
      Socket.connect();
    }

    if (payload?.distribuitor?.customActions) {
      if (!state.distribuitor) state.distribuitor = {};
      Object.assign(state.distribuitor, {
        customActions: payload?.distribuitor?.customActions,
      });

      const actions = state.distribuitor?.customActions || {};
      Object.keys(actions).forEach((act) => {
        Object.keys(actions[act].methods).forEach((mth) => {
          state.actions[actions[act].methods[mth].name] = true;
        });
      });
    }
  },

  REMOVE_USER_DATA(state) {
    Socket.disconnect();
    Object.keys(state).forEach((key) => {
      if (key !== "actions" && key !== "loadingTheme") state[key] = null;
    });
  },

  LOADING_THEME(state, payload) {
    state.loadingTheme = payload;
  },
  NEW_BUILD_DETECTED(state, payload) {
    state.newBuildDetected = payload;
  },
};

const actions = {
  async setUserData(context, user) {
    await Storage.setItem(USER_AUTH, user);

    await context.commit("USER_DATA", user);
  },

  async setDistDataAndTheme(context) {
    const user = (await Storage.getItem(USER_AUTH)) || {};

    if (!user?.distribuitor) user.distribuitor = {};

    context.commit("LOADING_THEME", true);
    const ret = await Open.readTheme();
    await store.commit("theme/UPDATE_THEME", ret.data);
    if (!ret.data) return;
    context.commit("LOADING_THEME", false);

    if (ret.data.customActions) {
      Object.assign(user.distribuitor, {
        customActions: ret.data?.customActions,
      });
    }

    if (ret.data.metaDescription) {
      document.querySelector("meta[name=description]").content =
        ret.data.metaDescription;
    }

    await Storage.setItem(USER_AUTH, user);

    await context.commit("USER_DATA", user);
  },

  async updateUserData(context, payload) {
    let user = structuredClone(context.state);
    const storeUser = await Storage.getItem(USER_AUTH);
    Object.assign(user, payload);
    Object.assign(user, { accessKey: storeUser.accessKey || user.accessKey });
    Object.assign(user, { token: storeUser.token || user.token });
    await Storage.setItem(USER_AUTH, user);

    await context.commit("USER_DATA", user);
  },

  async removeUserData(context) {
    await Storage.setItem(USER_AUTH);

    context.commit("REMOVE_USER_DATA");
  },

  async newBuildDetected(context, timestamp) {
    context.commit("NEW_BUILD_DETECTED", timestamp);
  },
};

const getters = {
  newBuildDetected: (state) => state.newBuildDetected,
  company: (state) => state.company,
  actions: (state) => state.actions,
  getUserData: (state) => state,
  checkPermissions:
    (state) =>
    (staffReqPerm, integratorReqPerm = [], onePermissionNeeded = false) => {
      let array = state.type === "staff" ? staffReqPerm : integratorReqPerm;

      if (state.type === "integrator" && integratorReqPerm.length === 0) {
        return false;
      }

      let check = true;

      if (state.type !== "admin") {
        let userPermissions = state.role?.permissions || [];

        if (userPermissions.length === 0 || state.type !== state.role.type) {
          check = false;
        }

        for (let permission of array) {
          if (onePermissionNeeded) {
            check = userPermissions.includes(permission);
            if (check) break;
          } else {
            check = check && userPermissions.indexOf(permission) !== -1;
          }
        }
      }

      return check;
    },
  limits: (state, getters) => {
    return {
      limitDiscountBudget: {
        check:
          state.type !== "admin" &&
          getters.checkPermissions(["budget.details.discount.limit"]),
        value: state.role?.limitDiscountBudget || 0,
      },
      limitDiscountOrder: {
        check:
          state.type !== "admin" &&
          getters.checkPermissions(["order.details.discount.limit"]),
        value: state.role?.limitDiscountOrder || 0,
      },
      limitIncreaseBudget: {
        check:
          state.type !== "admin" &&
          getters.checkPermissions(["budget.details.increase.limit"]),
        value: state.role?.limitIncreaseBudget || 0,
      },
      limitIncreaseOrder: {
        check:
          state.type !== "admin" &&
          getters.checkPermissions(["order.details.increase.limit"]),
        value: state.role?.limitIncreaseOrder || 0,
      },
    };
  },
  isBackOfficeUser: (state) => state.distribuitor?.marketplace || false,
  isPiedSupport: (state) => state.isPiedSupport,
  isProStaff: (state, getters) =>
    getters.hasAction("extraItem") &&
    getters.hasAction("importRequestFile") &&
    state.type !== "integrator",
  isProDistribuitor: (state, getters) =>
    getters.hasAction("extraItem") && getters.hasAction("importRequestFile"),
  isBasicDistributor: (state, getters) => !getters.isProDistribuitor,
  getActions: (state) =>
    Object.entries(state.actions || {})
      .filter(([, active]) => active)
      .map(([action]) => action),
  hasAction: (state, getters) => (action) =>
    getters.getActions.includes(action),
  logged: (state) => !!state.token,
  isStarter: (state, getters) =>
    !getters.hasAction("customTheme") || !getters.isProDistribuitor,
  accountType: (state) => state.type,
  isIntegrator: (state) => state.type === "integrator",
  isSupervisor: (state, getters) => getters.checkPermissions(["supervisor"]),
  isAdmin: (state) => state.type === "admin",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
