import { baseHTTP, baseDownloadHTTP } from "../http";
import { Pagination } from "@/components/global/class/Pagination";

export default {
  async existsApiToken() {
    return baseHTTP("get", `/app/staff/apiToken`);
  },

  async generateApiToken() {
    return baseHTTP("put", `/app/staff/apiToken`);
  },

  async importKits(formData) {
    return baseDownloadHTTP(
      "post",
      this.fileName("kit", { prepend: "Resultado Importação" }),
      true,
      `/app/staff/import/kit`,
      formData
    );
  },

  async finishImportProduct(productsData, remove, productType) {
    return baseDownloadHTTP(
      "post",
      this.fileName(productType, { prepend: "Resultado Importação" }),
      false,
      `/app/staff/finishImport/product/${productType}`,
      {
        productsData,
        remove,
      }
    );
  },

  async automaticKitGeneration(data) {
    return baseDownloadHTTP(
      "post",
      this.fileName("kit", { prepend: "Resultado Geração Automatica" }),
      false,
      `/app/staff/automaticKitGeneration`,
      data
    );
  },

  async testTemplate(id, name) {
    return baseDownloadHTTP(
      "get",
      `Orçamento ${name}.pdf`,
      true,
      `/app/staff/testTemplate/${id}`,
      null
    );
  },

  async downloadTemplate(id, name) {
    return baseDownloadHTTP(
      "get",
      `${name}.docx`,
      true,
      `/app/staff/downloadTemplate/${id}`,
      null
    );
  },

  async downloadIntegratorProposalTemplate(id, name) {
    return baseDownloadHTTP(
      "get",
      `${name}.docx`,
      true,
      `/app/staff/downloadIntegratorProposalTemplate/${id}`,
      null
    );
  },

  async testIntegratorProposalTemplate(templateId, name) {
    return baseDownloadHTTP(
      "get",
      `Proposta ${name}.pdf`,
      true,
      `/app/staff/testIntegratorProposalTemplate/${templateId}`,
      null
    );
  },

  async downloadTerms() {
    return baseDownloadHTTP(
      "get",
      "Termos e Condições.pdf",
      true,
      `/app/staff/download/terms`,
      null
    );
  },

  async downloadProducts(type) {
    return baseDownloadHTTP(
      "get",
      this.fileName(type),
      false,
      `/app/staff/download/products/${type}`,
      null
    );
  },

  async downloadRequest(type, range) {
    return baseDownloadHTTP(
      "get",
      this.fileName(type),
      true,
      `/app/staff/export/request/${type}`,
      { start: range[0], end: range[1] }
    );
  },

  async createRole(role) {
    return baseHTTP("post", `/app/staff/role`, role);
  },

  async updateRole(update, id) {
    return baseHTTP("put", `/app/staff/role/${id}`, { update });
  },

  async setRole(data) {
    return baseHTTP("put", `/app/staff/userRole`, data);
  },

  async deleteRole(id) {
    return baseHTTP("delete", `/app/staff/role/${id}`);
  },

  async readViewedKits(page, limit, filter = {}) {
    return baseHTTP("get", `/app/staff/viewedKits/${page}/${limit}`, filter);
  },

  async readInternalNote(requestType, requestId) {
    return baseHTTP(
      "get",
      `/app/staff/internal-note/${requestId}/${requestType}`
    );
  },

  async refreshRequestFreight(requestId) {
    return baseHTTP("put", `/app/staff/refreshFreight/${requestId}`);
  },

  async updateInternalNote(note, requestId, requestType) {
    let data = {
      note,
      requestId,
      requestType,
    };
    return baseHTTP("put", `/app/staff/internal-note`, data);
  },
  async updateChatAccess(data) {
    return baseHTTP("put", `/app/staff/chatAccess`, data);
  },

  async readIntegrators(page, limit, filter = {}) {
    return baseHTTP("get", `/app/staff/companies/${page}/${limit}`, filter);
  },

  async readSeller(query = {}, page = 1, limit = 25) {
    return baseHTTP("get", `/app/staff/seller`, {
      ...query,
      page,
      limit,
    });
  },

  async readResponsibles(query = {}) {
    return baseHTTP("get", `/app/staff/companies/responsibles`, query);
  },

  async readRequestsList(type) {
    return baseHTTP("get", `/app/staff/requestsList/${type}`);
  },

  async readCompaniesList() {
    return baseHTTP("get", `/app/staff/companies/list`);
  },

  async readRequestsResponsibles(query) {
    return baseHTTP("get", `/app/staff/requests/responsibles`, query);
  },

  async readMainContacts(query = {}) {
    return baseHTTP("get", `/app/staff/companies/mainContacts`, query);
  },

  async downloadStaff() {
    return baseDownloadHTTP(
      "get",
      this.fileName("staff"),
      false,
      `/app/staff/download/staff`
    );
  },

  async readRoles(cameFrom, select = []) {
    const query = {};
    if (select.length) query.select = select;
    return baseHTTP("get", `/app/staff/roles/${cameFrom}`, query);
  },

  async readIntegratorsSimple(page = 1, limit = 10, query = {}) {
    return baseHTTP(
      "get",
      `/app/staff/readIntegratorsSimple/${page}/${limit}`,
      query
    );
  },

  async readRecommendation() {
    return baseHTTP("get", `/app/staff/recommendationConditions`);
  },

  async downloadFormulas(type, formula = {}) {
    const { id = null, createdAt = null } = formula;
    const query = { type };
    if (id && createdAt) {
      query.id = id;
    }
    return baseDownloadHTTP(
      "get",
      this.fileName(type, { createdAt }),
      false,
      `/app/staff/export/excel/formulas/`,
      query
    );
  },

  async importExcelGeneric(formData, type) {
    return baseHTTP("post", `/app/staff/import/excel/${type}`, formData);
  },

  async readPrecificationConditions() {
    return baseHTTP("get", `/app/staff/precificationConditions`);
  },

  async readDeliveryConditions() {
    return baseHTTP("get", `/app/staff/deliveryConditions/`);
  },

  async readProductHistorical({ type, id }) {
    return baseHTTP("get", `/app/staff/productHistorical/${type}/${id}/`);
  },

  async readEquipments(type) {
    return baseHTTP("get", `/app/staff/equipments/${type}`);
  },

  async testFormulas(data) {
    return baseHTTP("post", `/app/staff/testFormulas`, data);
  },

  async getRemovedFormulaList(type, query) {
    return baseHTTP(
      "get",
      `/app/staff/distributorFormulas/removed/${type}`,
      query
    );
  },

  async reactivateFormula(id) {
    return baseHTTP("put", `/app/staff/distributorFormulas/reactivate/${id}`);
  },

  async readDiscountLimit(type) {
    return baseHTTP("get", `/app/staff/readDiscountLimit/${type}`);
  },

  async setDiscount(type, data) {
    return baseHTTP("put", `/app/staff/setDiscount/${type}`, data);
  },

  async changeRequestStatus(data) {
    return baseHTTP("put", `/app/staff/change/request/status`, data);
  },

  async changeIsToShowInvoiceInfoRequest(data) {
    return baseHTTP(
      "put",
      `/app/staff/change/request/isToShowInvoiceInfo`,
      data
    );
  },

  async createExtraItem(data) {
    return baseHTTP("post", `/app/staff/extraItem`, data);
  },

  async updateExtraItem(data) {
    return baseHTTP("put", `/app/staff/extraItem`, data);
  },

  async deleteExtraItem(id) {
    return baseHTTP("delete", `/app/staff/extraItem/${id}`);
  },

  async createCustomForm(
    title,
    required,
    typeField,
    answers,
    screen,
    active,
    messageHelp,
    allowedAnotherOption
  ) {
    return baseHTTP("post", `/app/staff/customForm`, {
      title,
      required,
      typeField,
      answers,
      screen,
      active,
      messageHelp,
      allowedAnotherOption,
    });
  },

  async createConfigCheckoutFile(data) {
    return baseHTTP("post", `/app/staff/configCheckoutFile`, data);
  },

  async updateCustomForm(
    title,
    required,
    typeField,
    answers,
    screen,
    active,
    id,
    messageHelp,
    allowedAnotherOption
  ) {
    let data = {
      id,
      update: {
        title,
        required,
        typeField,
        answers,
        screen,
        active,
        messageHelp,
        allowedAnotherOption,
      },
    };
    return baseHTTP("put", `/app/staff/customForm`, data);
  },

  async updateConfigCheckoutFile(data) {
    return baseHTTP("put", `/app/staff/configCheckoutFile`, data);
  },

  async deleteCustomForm(id) {
    return baseHTTP("delete", `/app/staff/customForm/${id}`);
  },

  async deleteConfigCheckoutFile(id) {
    return baseHTTP("delete", `/app/staff/deleteConfigCheckoutFile/${id}`);
  },

  async createAdvertisingPopUp(advertisingPopUp) {
    return baseHTTP("post", `/app/staff/advertisingPopUp`, advertisingPopUp);
  },

  async updateAdvertisingPopUp(advertisingPopUp) {
    return baseHTTP("put", `/app/staff/advertisingPopUp`, advertisingPopUp);
  },

  async addEditAdvertisingPopUpImage(file, advertisingPopUpId, pastFileSuffix) {
    if (!file) return;
    let formData = new FormData();
    formData.append("image", file);
    return baseHTTP(
      "put",
      `/app/staff/advertisingPopUpImage/${advertisingPopUpId}/${pastFileSuffix}`,
      formData
    );
  },

  async deleteAdvertisingPopUp(advertisingPopUpId) {
    return baseHTTP(
      "delete",
      `/app/staff/advertisingPopUp/${advertisingPopUpId}`
    );
  },

  async importTerms(formData) {
    return baseHTTP("post", `/app/staff/import/terms`, formData);
  },

  async responsibleSeller(data) {
    return baseHTTP("put", `/app/staff/responsibleSeller`, data);
  },
  async changeResponsibleSeller(oldSellers, newSeller) {
    return baseHTTP("put", `/app/staff/changeResponsibleSeller`, {
      oldSellers,
      newSeller,
    });
  },

  async createSeller(data) {
    return baseHTTP("post", `/app/staff/seller`, data);
  },

  async createIntegrator(data) {
    return baseHTTP("post", `/app/staff/integrator`, data);
  },

  async updateRequestStatus(update) {
    return baseHTTP("put", `/app/staff/request/status`, update);
  },

  async setDefaultRoleForUserType(data) {
    return baseHTTP("put", `/app/staff/setDefaultRoleForUserType`, data);
  },

  async updateStatusRestrictOptions(data) {
    return baseHTTP("put", `/app/staff/updateStatusRestrictOptions`, {
      ...data,
    });
  },

  async readMaintenanceStatus() {
    return baseHTTP("get", `/app/staff/maintenance-status`);
  },

  async updateMaintenanceStatus(maintenanceStatus) {
    return baseHTTP("put", `/app/staff/maintenance-status`, maintenanceStatus);
  },

  async deleteIntegrator(data) {
    let ids = new URLSearchParams(data).toString();
    return baseHTTP("delete", `/app/staff/integrator/${ids}`);
  },

  async changeIntegratorStatus(ids, status) {
    let data = {
      ids,
      status,
    };
    return baseHTTP("put", `/app/staff/integrator/change-status`, data);
  },

  async createSeparatedSalesSection(data) {
    return baseHTTP("post", `/app/staff/separatedSalesSection`, data);
  },

  async readSeparatedSalesSections() {
    return baseHTTP("get", `/app/staff/separatedSalesSections`);
  },

  async deleteSeparatedSalesSection(id) {
    return baseHTTP("delete", `/app/staff/separatedSalesSection/${id}`);
  },

  async updateSeparatedSalesSection(id, update) {
    return baseHTTP("put", `/app/staff/separatedSalesSection`, { id, update });
  },

  async updateSeparatedSalesSectionsOrderAndStatus(update) {
    return baseHTTP(
      "put",
      `/app/staff/separatedSalesSectionsOrderAndStatus`,
      update
    );
  },

  async createCategory(name, type) {
    return baseHTTP("post", `/app/staff/product_category`, { name, type });
  },
  async createStepMountKit(id, name, type) {
    return baseHTTP("post", `/app/staff/stepMountKit`, { id, name, type });
  },

  async readCategory(page = null, limit = null) {
    return baseHTTP("get", `/app/staff/product_category`, { page, limit });
  },

  async updateCategoryInStepMountKit(id, name, type) {
    let data = {
      id,
      update: {
        name,
        type,
      },
    };
    return baseHTTP("put", `/app/staff/categoryStepMountKit`, data);
  },

  async updateCategory(id, name, type) {
    let data = {
      id,
      update: {
        name,
        type,
      },
    };
    return baseHTTP("put", `/app/staff/product_category`, data);
  },

  async updateTemplate(data) {
    return baseHTTP("put", `/app/staff/updateTemplate`, data);
  },

  async updateIntegratorProposalTemplate(data) {
    return baseHTTP("put", `/app/staff/updateIntegratorProposalTemplate`, data);
  },

  async updateDefaultTemplate(id) {
    return baseHTTP("put", `/app/staff/defaultTemplate`, { id });
  },

  async updateDefaultIntegratorProposalTemplate(templateId) {
    return baseHTTP("put", `/app/staff/defaultIntegratorProposalTemplate`, {
      templateId,
    });
  },

  async deleteCategory(id) {
    return baseHTTP("delete", `/app/staff/product_category/${id}`);
  },

  async deleteCategoryInStepMountKit(id) {
    return baseHTTP("delete", `/app/staff/categoryStepMountKit/${id}`);
  },

  async createFileCategory(name) {
    return baseHTTP("post", `/app/staff/file-category`, { name });
  },

  async updateFileCategory(id, name, type) {
    let data = {
      id: id,
      update: {
        name,
        type,
      },
    };
    return baseHTTP("put", `/app/staff/file-category`, data);
  },

  async deleteFileCategory(id) {
    return baseHTTP("delete", `/app/staff/file-category/${id}`);
  },

  async readTemplates() {
    return baseHTTP("get", `/app/staff/templates`);
  },

  async readIntegratorProposalTemplates() {
    return baseHTTP("get", `/app/staff/integratorProposalTemplates`);
  },

  async createStructure(name, type) {
    return baseHTTP("post", `/app/staff/structure`, { name, type });
  },

  async updateStructure(data) {
    return baseHTTP("put", `/app/staff/structure`, data);
  },

  async deleteStructure(id) {
    return baseHTTP("delete", `/app/staff/structure/${id}`);
  },

  async updatePrecificationConditions(update) {
    return baseHTTP("put", `/app/staff/precificationConditions`, update);
  },

  async importTemplate(formData) {
    return baseHTTP("post", `/app/staff/import/template`, formData);
  },

  async updateActivatedTemplates(activatedTemplates) {
    return baseHTTP("put", `/app/staff/activatedTemplates`, {
      activatedTemplates,
    });
  },

  async updateIndicatorEditedRequestRecommendation(
    indicatorEditedRequestRecommendation
  ) {
    return baseHTTP(
      "put",
      `/app/staff/updateIndicatorEditedRequestRecommendation`,
      { indicatorEditedRequestRecommendation }
    );
  },

  async removeTerms() {
    return baseHTTP("delete", `/app/staff/remove/terms`);
  },

  async updateProductFile(product, id, type, file) {
    let formData = { delete: true };
    if (file) {
      formData = new FormData();
      formData.append(type, file);
    }

    return baseHTTP(
      "put",
      `/app/staff/product/uploadFile/${product}/${id}/${type}`,
      formData
    );
  },

  async readAllProductCodes() {
    return baseHTTP("get", `/app/staff/readAllProductCodes`);
  },

  async deleteSeller(sellersIds) {
    return baseHTTP("delete", `/app/staff/seller/`, { ids: sellersIds });
  },

  async updateSellerInfo(data) {
    return baseHTTP("put", `/app/staff/seller`, data);
  },

  async updateFreight(data) {
    return baseHTTP("put", `/app/staff/deliveryConditions`, data);
  },

  async deliverySettings(data) {
    return baseHTTP("put", `/app/staff/deliverySettings`, data);
  },

  async updateSideBar(update) {
    return baseHTTP("put", `/app/staff/sideBar`, update);
  },

  async updateRecommendation(data) {
    return baseHTTP("put", `/app/staff/recommendationConditions`, data);
  },

  async updateIntegratorMainContact(data) {
    return baseHTTP("put", `/app/staff/integrator/mainContact`, data);
  },

  async updateIntegratorCompany(data) {
    return baseHTTP("put", `/app/staff/integrator/company`, data);
  },

  async changeSeller(id, sellerId, requestType) {
    let data = {
      id: id,
      update: {
        seller: sellerId,
      },
    };
    return baseHTTP("put", `/app/staff/change/${requestType}/seller`, data);
  },

  async removeRequest(type, id) {
    return baseHTTP("delete", `/app/staff/request/${type}/${id}`);
  },

  async deleteTemplate(id) {
    return baseHTTP("delete", `/app/staff/template/${id}`);
  },

  async deleteIntegratorProposalTemplate(templateId) {
    return baseHTTP(
      "delete",
      `/app/staff/integratorProposalTemplate/${templateId}`
    );
  },

  async readSellerBudgets(id, page, limit) {
    return baseHTTP("get", `/app/staff/seller/budget/${id}/${page}/${limit}`);
  },

  async readSellerOrders(id, page, limit) {
    return baseHTTP("get", `/app/staff/seller/order/${id}/${page}/${limit}`);
  },

  async readStaffById(staffId) {
    return baseHTTP("get", `/app/staff/staff/readById/${staffId}`);
  },

  async dashboardIntegratorsBudgetsOrdersAmount() {
    return baseHTTP(
      "get",
      `/app/staff/dashboard/integrators/budgets/orders/amount`
    );
  },

  async dashboardOrdersBySeller() {
    return baseHTTP("get", `/app/staff/dashboard/orders-by-seller`);
  },

  async dashboardTop20IntegratorsByBudget() {
    return baseHTTP("get", `/app/staff/dashboard/top-integrator-budgets`);
  },

  async dashboardTop20IntegratorsByOrder() {
    return baseHTTP("get", `/app/staff/dashboard/top-integrator-orders`);
  },

  async dashboardTop20LastIntegratorsLogin() {
    return baseHTTP("get", `/app/staff/dashboard/last-integrators-login`);
  },

  async dashboardTop20LastIntegratorsSingin() {
    return baseHTTP("get", `/app/staff/dashboard/last-integrators-sing-in`);
  },

  // ##############################################
  //          PRODUTOS
  // ##############################################

  async readProducts(params) {
    return baseHTTP(
      "get",
      `/app/staff/products/${params.type}/${params.page}/${params.limit}`,
      params
    );
  },

  async readProduct(type, id) {
    return baseHTTP("get", `/app/staff/product/${type}/${id}`);
  },

  async deleteProduct(type, id) {
    return baseHTTP("delete", `/app/staff/product/${type}/${id}`);
  },

  async saveProduct(id, type, data) {
    if (id) {
      return baseHTTP("put", `/app/staff/product/${type}`, {
        id,
        update: data,
      });
    } else {
      return baseHTTP("post", `/app/staff/product/${type}`, data);
    }
  },

  async importProduct(formData, productType) {
    return baseHTTP("post", `/app/staff/import/excel/${productType}`, formData);
  },

  async importIntegrators(formData) {
    return baseHTTP("post", `/app/staff/import/integrator`, formData);
  },

  async createKit(data) {
    return baseHTTP("post", `/app/staff/kit`, data);
  },

  async updateKit(data) {
    return baseHTTP("put", `/app/staff/kit`, data);
  },

  async readKits(page, limit) {
    return baseHTTP("get", `/app/staff/kit/${page}/${limit}`);
  },

  // ##############################################
  //          SETTINGS
  // ##############################################

  async readEmailSettings(type) {
    return baseHTTP("get", `/app/staff/emailSettings/${type}`);
  },

  async updateEmailSettings(type, emailSettings) {
    return baseHTTP("put", `/app/staff/emailSettings/${type}`, {
      emailSettings,
    });
  },

  async readEmailAutomations(query) {
    return baseHTTP("get", `/app/staff/emailAutomations`, query);
  },

  async createEmailAutomation(data) {
    return baseHTTP("post", `/app/staff/emailAutomations`, data);
  },

  async updateEmailAutomation(id, data) {
    return baseHTTP("put", `/app/staff/emailAutomations/${id}`, data);
  },

  async deleteEmailAutomation(id) {
    return baseHTTP("delete", `/app/staff/emailAutomation/${id}`);
  },

  async readEmailTriggers() {
    return baseHTTP("get", `/app/staff/emailTriggers`);
  },

  async updateEmailTriggers(type, triggers) {
    return baseHTTP("put", `/app/staff/emailTriggers/${type}`, { triggers });
  },

  async readEmailTemplates() {
    return baseHTTP("get", "/app/staff/emailTemplates");
  },

  async readEmailDefaultTemplates() {
    return baseHTTP("get", "/app/staff/emailDefaultTemplates");
  },

  async readEmailTemplate(id) {
    return baseHTTP("get", `/app/staff/emailTemplates/${id}`);
  },

  async createEmailTemplate(data) {
    return baseHTTP("post", `/app/staff/emailTemplates`, data);
  },

  async updateEmailTemplate(id, data) {
    return baseHTTP("put", `/app/staff/emailTemplates/${id}`, data);
  },

  async deleteEmailTemplate(id) {
    return baseHTTP("delete", `/app/staff/emailTemplate/${id}`);
  },

  async readCustomEmail(filter) {
    return baseHTTP("get", `/app/staff/customEmail`, filter);
  },

  async updateCustomEmail(data) {
    return baseHTTP("put", `/app/staff/customEmail`, data);
  },

  async sendEmailTest(toSend) {
    return baseHTTP("post", `/app/staff/sendEmailTest`, toSend);
  },

  async updateShoppingSettingsKits(update) {
    return baseHTTP("put", `/app/staff/shoppingSettingsKits`, update);
  },

  async updateSizingSettings(update) {
    return baseHTTP("put", `/app/staff/settings/sizing`, update);
  },

  async updateCrmSettings(update) {
    return baseHTTP("put", `/app/staff/settings/crmModule`, update);
  },

  async readMessages(group = "all") {
    return baseHTTP("get", `/app/staff/readMessages/${group}`);
  },

  async updateMessages(messages) {
    return baseHTTP("put", `/app/staff/updateMessages`, { messages });
  },

  async updateBudgetSetting(data) {
    return baseHTTP("put", `/app/staff/budget-setting`, data);
  },

  async updateOrderMessages(messages) {
    return baseHTTP("put", `/app/staff/orderMessages`, { messages });
  },

  async updateThemeDistributor(formData) {
    return baseHTTP("put", `app/staff/theme`, formData);
  },

  async updateThemeEcommerce(formData) {
    return baseHTTP("put", `app/staff/theme/ecommerce`, formData);
  },

  async readDiscountSetting() {
    return baseHTTP("get", `/app/staff/discountSetting`);
  },

  async readMaxDiscountInRequest() {
    return baseHTTP("get", `/app/staff/maxDiscount`);
  },

  async readAllowedResponsibleSellers() {
    return baseHTTP("get", `/app/staff/allowedResponsibleSellers`);
  },

  async readSubordinatedSellers() {
    return baseHTTP("get", `/app/staff/subordinatedSellers`);
  },

  async fetchPossibleResponsibleSellersForUser(id = "") {
    return baseHTTP("get", `/app/staff/responsibles/user/${id}`);
  },

  async countDiscountRequisitionsToApprove(type) {
    return baseHTTP(
      "get",
      `/app/staff/request/discountRequisition/count/${type}`
    );
  },

  async createDiscountRequisition(data) {
    return baseHTTP("post", `/app/staff/request/discountRequisition`, data);
  },

  async changeDiscountRequisitionStatus(id, status) {
    return baseHTTP(
      "put",
      `/app/staff/request/discountRequisition/status/${status}/${id}`
    );
  },

  async changeDiscountRequisitionResponsible(id, data) {
    return baseHTTP(
      "put",
      `/app/staff/request/discountRequisition/responsible/${id}`,
      data
    );
  },

  async removeDiscountRequisition(id) {
    return baseHTTP("delete", `/app/staff/requests/discountRequisition/${id}`);
  },

  async updateDiscountSetting(data) {
    return baseHTTP("put", `/app/staff/discountSetting`, data);
  },

  async updateSaleSetting(data) {
    return baseHTTP("put", `/app/staff/saleSetting`, data);
  },

  async createPaymentMethod(data) {
    return baseHTTP("post", `/app/staff/paymentMethod`, data);
  },

  async updatePaymentMethod(data) {
    return baseHTTP("put", `/app/staff/paymentMethod`, data);
  },

  async togglePaymentMethod(data) {
    return baseHTTP("put", `/app/staff/paymentMethod/toggle`, data);
  },

  async fetchPaymentMethods(query) {
    return baseHTTP("get", `/app/staff/paymentMethods`, query);
  },

  async deletePaymentMethod(id) {
    return baseHTTP("delete", `app/staff/paymentMethod/${id}`);
  },

  async updateRequestPayment(data, id) {
    return baseHTTP("put", `/app/staff/request/payment/${id}`, data);
  },

  async updateBannerGroup(data) {
    return baseHTTP("put", `/app/staff/bannerGroup`, data);
  },

  async toggleBannerGroup(data) {
    return baseHTTP("put", `/app/staff/bannerGroup/toggle`, data);
  },

  async fetchBannerGroups(query) {
    return baseHTTP("get", `/app/staff/bannerGroups`, query);
  },

  async fetchBannerGroup(type, query) {
    return baseHTTP("get", `/app/staff/bannerGroup/${type}`, query);
  },

  async updateBannerGroupOrdernable(type, banners = []) {
    return baseHTTP("put", `/app/staff/bannerGroups/${type}/order`, {
      banners,
    });
  },

  async removeBannerGroup(type, index = 0) {
    return baseHTTP("delete", `/app/staff/bannerGroups/${type}/${index}`);
  },

  async updateDistributorSettings(data) {
    return baseHTTP("put", `/app/staff/distributorSettings`, data);
  },

  async updateEcommerceGeneralSettings(data) {
    return baseHTTP("put", `/app/staff/ecommerceGeneralSettings`, {
      general: data,
    });
  },

  async createEcommerceBanner(formData) {
    return baseHTTP("post", "app/staff/ecommerce/banner/carousel", formData);
  },

  async updateEcommerceBanner(id, formData) {
    return baseHTTP("put", `app/staff/ecommerce/banner/${id}`, formData);
  },

  async deleteEcommerceBanner(id) {
    return baseHTTP("delete", `app/staff/ecommerce/banner/${id}`);
  },

  async fetchEcommerceBanner() {
    return baseHTTP("get", "app/staff/ecommerce/banners");
  },

  async updateSupervisors(data) {
    return baseHTTP("put", `/app/staff/supervisors`, data);
  },

  async importIntegratorProposalTemplate(formData) {
    return baseHTTP(
      "post",
      `/app/staff/import/integratorProposalTemplate`,
      formData
    );
  },

  fileName(type, options = {}) {
    const { prepend = "", extension = "xlsx", createdAt = null } = options;
    const standartName = {
      term: "Termo",

      budget: "Orçamentos",
      order: "Pedidos",

      staff: "Colaboradores",

      module: "Módulos",
      inverter: "Inversores",
      stringBox: "String Box",
      complementaryEquipment: "Outros Produtos",
      kit: "Kits Fechados",
      integrator: "Empresas de Energia",
      precification: "Precificação",
      freight: "Frete",
      recommendation: "Recomendação",
      viewedKits: "Kits Pesquisados",
      salesGoal: "Resultado Metas de Vendas",
      commission: "Resultado Comissão",
    };

    const now = createdAt
        ? new Date(createdAt).getTime()
        : new Date().getTime(),
      date = new Date(now).toBRDate(),
      time = new Date(now).toBRTime();

    const [year, month, day] = date.split("/");
    const [hour, minute, second] = time.split(":");

    return `${prepend !== "" ? prepend + " - " : ""}${
      standartName[type]
    } - ${day}-${month}-${year} ${hour}-${minute}-${second}.${extension}`;
  },

  term: {
    list: (query = {}) => baseHTTP("get", `/app/staff/terms`, query),
    view: (id) => baseHTTP("get", `/app/staff/terms/${id}`),
    download: (id, query) =>
      baseDownloadHTTP(
        "get",
        "Termos de Uso.pdf",
        true,
        `/app/staff/terms/download/${id}`,
        query
      ),
    create: (payload) => baseHTTP("post", `/app/staff/terms`, payload),
    update: (id, payload) => baseHTTP("put", `/app/staff/terms/${id}`, payload),
    activeToggle: (id, active, system = "pied") =>
      baseHTTP("patch", `/app/staff/terms/${id}`, { active, system }),
    remove: (id) => baseHTTP("delete", `/app/staff/terms/${id}`),
  },

  exportSolarEnergyCompany(filter = {}) {
    return baseDownloadHTTP(
      "get",
      this.fileName("integrator"),
      true,
      "/app/staff/export/integrator",
      filter
    );
  },

  async readFeaturedProduct(page, limit) {
    return baseHTTP("get", `/app/staff/featured/product/${page}/${limit}`);
  },

  async readFeaturedKit(page, limit) {
    return baseHTTP("get", `/app/staff/featured/kit/${page}/${limit}`);
  },

  async readNotFeaturedProduct(type) {
    return baseHTTP("get", `/app/staff/notFeatured/product/${type}`);
  },
  async readNotFeaturedKit() {
    return baseHTTP("get", `/app/staff/notFeatured/kit`);
  },

  async updateFeaturedProduct(data) {
    return baseHTTP("put", `/app/staff/featured/product`, data);
  },

  async updateFeaturedKit(data) {
    return baseHTTP("put", `/app/staff/featured/kit`, data);
  },

  async fetchDistributionCenters() {
    return baseHTTP("get", `/app/staff/distributionCenters`);
  },

  async fetchDistributionCenter(id) {
    return baseHTTP("get", `/app/staff/distributionCenter/${id}`);
  },

  async deleteDistributionCenter(id) {
    return baseHTTP("delete", `/app/staff/distributionCenter/${id}`);
  },

  async fetchDistributionRegions() {
    return baseHTTP("get", `/app/staff/distributionRegions`);
  },

  async fetchDistributionRegion(id) {
    return baseHTTP("get", `/app/staff/distributionRegion/${id}`);
  },

  async deleteDistributionRegion(id) {
    return baseHTTP("delete", `/app/staff/distributionRegion/${id}`);
  },

  async saveDistributionCenter(data) {
    if (data.id) {
      return baseHTTP("put", `/app/staff/distributionCenter`, data);
    } else {
      return baseHTTP("post", `/app/staff/distributionCenter`, data);
    }
  },

  async saveDistributionRegion(data) {
    if (data.id) {
      return baseHTTP("put", `/app/staff/distributionRegion`, data);
    } else {
      return baseHTTP("post", `/app/staff/distributionRegion`, data);
    }
  },

  async saveDistributionRegionPriority(data) {
    return baseHTTP("put", `/app/staff/distributionRegionPriority`, data);
  },

  downloadConditions(type, query = {}) {
    const path = `/app/staff/export/excel/${type}`;

    return baseDownloadHTTP("get", this.fileName(type), false, path, query);
  },

  createOrUpdateProductHighlights(data = {}) {
    const { _id = undefined } = data;
    return baseHTTP(
      _id ? "put" : "post",
      _id
        ? `/app/staff/productHighlights/${_id}`
        : `/app/staff/productHighlights`,
      data
    );
  },

  toggleActiveProductHighlights(id, active) {
    return baseHTTP("patch", `/app/staff/productHighlights/${id}`, { active });
  },

  findProductHighlights(query = {}) {
    return baseHTTP("get", "/app/staff/productHighlights", query);
  },

  readProductAutocomplete(query = {}) {
    return baseHTTP("get", "/app/staff/products/autocomplete", query);
  },

  removeProductHighlight(id) {
    return baseHTTP("delete", `/app/staff/productHighlights/${id}`);
  },

  async createSalesGoal(data) {
    return baseHTTP("post", "app/staff/salesGoal", data);
  },

  async updateSalesGoal(data) {
    return baseHTTP("put", "app/staff/salesGoal", data);
  },

  async batchUpdateSalesGoal(data) {
    return baseHTTP("put", "app/staff/salesGoal/batch", data);
  },

  async deleteSalesGoal(data) {
    return baseHTTP("delete", "app/staff/salesGoal/", data);
  },

  async readSalesGoal(query = {}, page = 1, limit = 2) {
    return baseHTTP("get", "app/staff/salesGoal", {
      ...query,
      page,
      limit,
    });
  },

  async downloadSalesGoal(range) {
    return baseDownloadHTTP(
      "get",
      this.fileName("salesGoal"),
      true,
      `app/staff/export/salesGoal/${range[0]}/${range[1]}`
    );
  },

  async createCommission(data) {
    return baseHTTP("post", "app/staff/commission", data);
  },

  async updateCommission(data) {
    return baseHTTP("put", "app/staff/commission", data);
  },

  async batchUpdateCommission(data) {
    return baseHTTP("put", "app/staff/commission/batch", data);
  },

  async deleteCommission(data) {
    return baseHTTP("delete", "app/staff/commission/", data);
  },

  async readCommission(query = {}, page = 1, limit = 2) {
    return baseHTTP("get", "app/staff/commission", {
      ...query,
      page,
      limit,
    });
  },

  downloadCommission(range) {
    return baseDownloadHTTP(
      "get",
      this.fileName("commission"),
      true,
      `app/staff/export/commission/${range[0]}/${range[1]}`
    );
  },

  /**
   * @param filter {Record<string, any>}
   * @param pagination {Pagination}
   * @return {Promise<{data: any[], error: boolean}>}
   */
  findContacts(filter = { company: null }, pagination) {
    const { company, ...extraFilter } = filter;
    return baseHTTP(
      "get",
      `app/staff/companies/${company}/contacts/${pagination.currentPage}/${pagination.itemsPerPage}`,
      extraFilter
    );
  },

  createOrUpdateContact(data) {
    return baseHTTP(
      "post",
      `app/staff/companies/${data.company}/contacts/${data?.id || ""}`.trim(),
      data
    );
  },

  deleteContact(data) {
    return baseHTTP(
      "delete",
      `app/staff/companies/${data.company}/contacts/${data?.id || ""}`.trim()
    );
  },
};
